.main-page{
    position: fixed; 
    top: 0; 
    left: 0; 
    
    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;

    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 82.81%), url('.\\assets\\stars.jfif') ; 
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;
}

.main-page img {
    display: block;
    margin: auto;
}

.header{
    text-align: left;
    position: relative; 
    top: 20; 
    left: 20; 
    font-size: 25px;
    font-weight: 500;
    color: linear-gradient(270deg, rgba(255, 24, 0, 0.5) 0%, rgba(255, 255, 255, 0) 50%);
}