$background-color: black;
$text-color: white;
$alt-color: #4285f4;

.custom-button {
    background-color: $background-color;
    color: $text-color;

    width: auto;

    height: 80px;
    line-height: 50px;

    padding: 0 35px 0 35px;

    letter-spacing: 0.5px;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bolder;
    cursor: pointer;

    &:hover{
        background-color:  $text-color;
        color: $background-color;
    }


    &.google-sign-in{
        background-color: $alt-color;
        min-width: 300px;
        &:hover{
            background-color: $text-color;
            color: $alt-color;
            border-color: $alt-color;
        }
    }

    &.Welcome{
        background: none;
        min-width: 300px;
        border: 3px solid black;
        color: black;

        &:hover{
            background-color: black;
            color: white;
        }
    }

    &.Submit{
        font-size: 10px;
        min-width: 50px;
        height: 50px;
    }

    &.Showcase{
        min-width: 300px;
        background-color: #2C3DDB;
        border: none;
        color: white;

        &:hover{
            color: #DB562C;
        }
    }

    &.Sign-In{
        min-width: 300px;
        background-color: #DB562C;
        border: none;
        color: white;

        &:hover{
            color: #2C3DDB;
        }
    }


    &.No{
        min-width: 80px;
        background-color: #DB562C;
        border: none;
        color: white;

        
        margin:auto;

        &:hover{
            color: rgba(48, 85, 19, 1);
        }
    }

    &.Yes{
        min-width: 80px;
        background-color: rgba(48, 85, 19, 1);
        border: none;
        margin:auto;
        color: white;

        &:hover{
            color:  #DB562C;
        }
    }
    
}