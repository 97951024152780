.device-info{
    padding: 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .all-details {
        margin: 20px;
        width: 500px;

        .details-group {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: space-between;

            .detail-value {
                color: gray;
            }

        }
    }

    
    .device-changes {
        margin: 20px;
        min-width: 480px;
    }
}
